import React from 'react';
import Button from 'react-bootstrap/Button';
import { isSsr } from 'utils/isSsr';
import cx from 'classnames';
import { DynamicContentOverlay } from 'components/DynamicContentOverlay';
import { LocationContext } from 'containers/Location/LocationContext';
import { CurrencyContext } from 'containers/Currency/CurrencyContext';
import { priceFormat } from './utils';
import './styles.scss';

interface IProps {
  variant: 'primary' | 'secondary' | 'danger';
  price: number;
  currency: string;
  packageSize: number;
  onActionBtnClick?: () => void;
  customActionBtn?: React.ReactNode;
  shine?: boolean;
}

export const PriceCard = ({
  variant,
  price,
  currency,
  packageSize,
  onActionBtnClick,
  customActionBtn,
  shine,
}: IProps) => {
  const lessonPrice = price / packageSize;
  const location = React.useContext(LocationContext);
  const currencyRate = React.useContext(CurrencyContext);

  return (
    <div className={`price-card price-card-${variant}`}>
      <div className="price-lessons">
        {packageSize} {packageSize > 4 ? ' уроков' : 'урока'}
      </div>
      <div className="package-price">
        {!isSsr && priceFormat(currency, price, 2)}
      </div>
      <div className="lesson-price">
        {!isSsr && (
          <>
            <b>{priceFormat(currency, lessonPrice, 2)}</b> за урок
          </>
        )}
      </div>
      {currencyRate && (
        <DynamicContentOverlay>
          <div className="price-local">
            примерно{' '}
            {priceFormat(location.currencyCode, price * currencyRate, 0)}
          </div>
        </DynamicContentOverlay>
      )}
      {customActionBtn || (
        <Button
          onClick={onActionBtnClick}
          size="lg"
          variant="secondary"
          className={cx('action-btn', shine && 'btn-shine')}
        >
          Записаться
        </Button>
      )}
    </div>
  );
};
