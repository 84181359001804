import React, { SyntheticEvent } from 'react';
import cx from 'classnames';
import './styles.scss';

interface IProps {
  activeIndex: number;
  onClick: (index: number) => void;
}

export const PriceSwitch = ({ activeIndex, onClick }: IProps) => {
  const handleClick = React.useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const index = e.currentTarget.getAttribute('data-index') as string;
      onClick(parseInt(index, 10));
    },
    [],
  );

  return (
    <div className="price-switch">
      <button
        data-index={0}
        onClick={handleClick}
        className={cx('switch-control switch-control-primary', {
          active: activeIndex === 0,
        })}
      >
        Индивидуально-групповое
        <br />
        <small>60 минут</small>
      </button>
      <button
        data-index={1}
        onClick={handleClick}
        className={cx('switch-control switch-control-danger', {
          active: activeIndex === 1,
        })}
      >
        Индивидуальное
        <br />
        <small>30 минут</small>
      </button>
      <button
        data-index={2}
        onClick={handleClick}
        className={cx('switch-control switch-control-secondary', {
          active: activeIndex === 2,
        })}
      >
        Индивидуальное
        <br />
        <small>60 минут</small>
      </button>
    </div>
  );
};
