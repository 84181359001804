import React from 'react';
import BootstrapCarousel from 'react-bootstrap/Carousel';

interface IProps {
  defaultActiveIndex: number;
  header: (activeIndex: number, handleSwitch: (index: number) => void) => void;
  children: React.ReactNode[];
  controllerImgRight: React.ElementType;
  controllerImgLeft: React.ElementType;
  className?: string;
}

export const Carousel = ({
  defaultActiveIndex,
  header,
  children,
  controllerImgRight,
  controllerImgLeft,
  className,
}: IProps) => {
  const [activeIndex, setActive] = React.useState(defaultActiveIndex);
  const handleSwitch = React.useCallback((index) => {
    setActive(index);
  }, []);

  const ControllerLeft = controllerImgLeft;
  const ControllerRight = controllerImgRight;

  return (
    <>
      {header && header(activeIndex, handleSwitch)}
      <BootstrapCarousel
        interval={null}
        indicators={false}
        className={className}
        nextIcon={<ControllerRight className="carousel-img" />}
        prevIcon={<ControllerLeft className="carousel-img" />}
        nextLabel={null}
        prevLabel={null}
        onSelect={setActive}
        activeIndex={activeIndex}
      >
        {React.Children.map(children, (child, index) => (
          <BootstrapCarousel.Item key={index}>{child}</BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </>
  );
};

Carousel.defaultProps = {
  defaultSlideIndex: 0,
  slides: 0,
  header: null,
};
