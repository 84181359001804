export const priceFormat = (
  currency: string,
  price: number,
  floatingDigits: number,
) =>
  new Intl.NumberFormat(navigator.language, {
    currency,
    style: 'currency',
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: floatingDigits,
  }).format(price);
